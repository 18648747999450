import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // // add authorization header with basic auth credentials if available
        // const currentUser = this.authenticationService.currentUserValue;
        // if (currentUser && currentUser.access_token) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer  ${currentUser.access_token}`
        //         }
        //     });
        // }

        return next.handle(request);
    }
}