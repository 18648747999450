import { Component, HostListener } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Procric8';
  constructor() { }

  ngOnInit(): void {
    // window.onbeforeunload = function() {
    //   console.log("In")
    //   localStorage.clear();
    // };
  }
}
