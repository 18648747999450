<div class="header-container" style="width: 100%;">
    <div class="row" style="width: 100%;">
        <div class="col-1 pl-0 d-flex justify-content-left align-items-center">
            <nb-actions size="medium">
                <nb-action class="control-item pl-0" icon="arrow-ios-back-outline" (click)="backToTournament()" *ngIf="showUrl"></nb-action>
            </nb-actions>
            <!-- <img src="assets/images/arrow-ios-back-outline.svg" (click)="backToTournament()" width="30" class="mb-0" > -->
        </div>
        <div class="col-10 text-center pr-0 d-flex justify-content-center align-items-center">
            <h5 class="mb-0" *ngIf="dataServiceClass.headerTextGet == null" style="color: #eb652b;">Procri8</h5>
            <span style="font-weight: 700;font-size: 16px;" *ngIf="dataServiceClass.headerTextGet">{{dataServiceClass.headerTextGet}}</span>
        </div>
        <div class="col-1 mt-1 pr-0 d-flex justify-content-end" *ngIf="route == '/match'">
            <nb-actions size="small">
                <nb-action class="control-item pr-0" icon="funnel-outline"></nb-action>
                <nb-action class="control-item pr-0" icon="search-outline"></nb-action>
            </nb-actions>
        </div>
    </div>
    <nb-actions size="small">`
        <!-- <nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action> -->
        <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
        <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <!-- *nbIsGranted="['view', 'user']" -->
        <!-- <nb-action class="user-action">
            <nb-user nbContextMenuTag="headerMenu" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture" (itemClick)="logOut()">
            </nb-user>
        </nb-action> -->
    </nb-actions>
</div>