import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertService } from "../service/alert.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alert: AlertService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        // console.log(err);
        if (err.status === 403) {
          // this.alert.showToast("danger", "Validation Error", err.error.message);
        }
        if (err.status === 404) {
          // this.alert.showToast("danger", err.error.message);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
