<div class="bottnav">
  <ul class="nav nav-pills text-center" id="pills-tab">
    <li class="nav-item">
      <a class="nav-link" id="invitation-tab" data-toggle="pill" (click)="onRoute('home')" aria-controls="invitation"
        aria-selected="true">
        <p class="m-0 font-14-12" [class.active]="dataServiceClass.menuActiveGet == 'Home'"><span class="font-18-16"><i
              class="fas
                              fa-home"></i></span><br>Home
        </p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="match-tab" data-toggle="pill" (click)="onRoute('Match')" aria-controls="match"
        aria-selected="false">
        <p class="m-0 font-14-12" [class.active]="dataServiceClass.menuActiveGet == 'Match'"><span
            class="font-18-16 mr-b5"><i class="fas fa-volleyball-ball"></i></span><br>Match</p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="tournament-tab" data-toggle="pill" (click)="onRoute('Tournament')"
        aria-controls="tournament" aria-selected="false">
        <p class="m-0 font-14-12" [class.active]="dataServiceClass.menuActiveGet == 'Tournament'"><span
            class="font-18-16"><i class="fas
                              fa-trophy"></i></span><br>Tournament
        </p>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="tournament-tab" data-toggle="pill" (click)="onRoute('Login')" aria-controls="tournament"
        aria-selected="false">
        <p class="m-0 font-14-12"><span class="font-18-16"><i class="fas
                              fa-user"></i></span><br>Login
        </p>
      </a>
    </li>
  </ul>
  <!-- <div class="links">
    <ul>
      <li><a href="http://procric8.com/pdf/Privacy%20Policy.pdf" target="_blank">Privacy Policy</a></li>
      <li><a href="http://procric8.com/pdf/Refund%20Policy.pdf" target="_blank">Refund Policy</a></li>
      <li><a href="http://procric8.com/pdf/Terms%20And%20Conditions.pdf" target="_blank">Terms and conditions</a></li>
      <li><a href="http://www.margosatree.com/#contact" target="_blank">Contact Us</a></li>
    </ul>
    <h2>©{{year | date: 'yyyy'}} Powered By
      Margosatree Technologies LLP</h2>
    <div class="text-center my-2">
      <a class="contact-us-title" (click)="onRoute('contact')">Contact Us</a>
      <span> Email: <a href="mailto:harsh@margosatree.com"> harsh@margosatree.com</a></span>
      <span> Phone: <a href="tel:harsh@margosatree.com">9870016616</a></span>
    </div>
  </div> -->
</div>