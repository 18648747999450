import { Router } from "@angular/router";
import { Component } from "@angular/core";
import { DataServiceClass } from "src/app/service/DataService.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  templateUrl: "./footer.component.html",
})
export class FooterComponent {
  public year: Date;
  constructor(
    private router: Router,
    public dataServiceClass: DataServiceClass
  ) {
    this.year = new Date();
  }
  users: { name: string; title: string }[] = [
    { name: "Home", title: "" },
    { name: "Match", title: "" },
    { name: "Tournament", title: "" },
    { name: "Login", title: "" },
  ];

  onRoute(val: string) {
    this.dataServiceClass.headerTextSet = null;
    switch (val) {
      case "home":
        this.dataServiceClass.menuActiveSet = "Home";
        this.router.navigate([""]);
        break;
      case "Login":
        window.open(environment.USER_PANEL_URL, "_blank");
        break;
      case "Match":
        this.dataServiceClass.menuActiveSet = "Match";
        this.router.navigate(["match"]);
        break;
      case "Tournament":
        this.dataServiceClass.menuActiveSet = "Tournament";
        if (this.dataServiceClass.tournamentDetailGet == null) {
          this.router.navigate(["tournament-list"]);
        } else {
          this.dataServiceClass.headerTextSet =
            this.dataServiceClass.tournamentDetailGet.tourName;
          this.router.navigate(["tournament-detail"]);
        }
        break;
      case "contact":
        this.dataServiceClass.menuActiveSet = "";
        this.router.navigate(["/contact-us"]);
        break;
    }
  }

  public open(link: string): void {
    // console.log(link);
    window.open(link, "_blank");
  }
}
