import { Router, NavigationError, NavigationEnd } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { DataServiceClass } from "src/app/service/DataService.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public showUrl: boolean = false;
  userPictureOnly: boolean = false;
  public route: string;
  user: any;

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu: any = [
    { title: "Profile", fun: "myProfile" },
    { title: "Log out", fun: "logOut" },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public dataServiceClass: DataServiceClass,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
        this.showRoute(this.route);
      }
    });
  }

  ngOnInit() {
    //console.log(this.dataServiceClass.logoFlagGet);
    //this.dataServiceClass.tournamentDetailGet == undefined ? 'assets/images/Procric8.png' : this.dataServiceClass.tournamentDetailGet
    this.currentTheme = this.themeService.currentTheme;
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        this.user = users.nick;
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
      });

    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "headerMenu"))
      .subscribe((title) => {
        this[title.item["fun"]]();
      });
  }

  showRoute(route: any) {
    if (
      route == "/" ||
      route == "/match" ||
      route == "/tournament-list" ||
      route == "/contact-us"
    ) {
      this.showUrl = false;
    } else {
      this.showUrl = true;
    }
  }

  backToTournament() {
    console.log(this.route);
    this.dataServiceClass.routesSet = this.route;
    const changeRoute = this.route.split("?")[0];
    switch (changeRoute) {
      case "/story-detail":
        this.router.navigate(["/"]);
        break;
      case "/tournament-detail":
        this.dataServiceClass.headerTextSet = null;
        this.router.navigate(["/tournament-list"]);
        break;
      case "/completedScoreBoard":
      case "/upcomingplayersdetail":
      case "/liveScoreBoard":
        {
          if (this.dataServiceClass.tournamentDetailGet) {
            this.dataServiceClass.headerTextSet =
              this.dataServiceClass.tournamentDetailGet.tourName;
            this.router.navigate(["/tournament-detail"], {
              queryParams: {
                tourId: this.dataServiceClass.tournamentDetailGet.id,
                tab: this.dataServiceClass.activeTabGet.toLocaleLowerCase(),
              },
            });
          } else {
            this.dataServiceClass.headerTextSet = null;
            this.router.navigate(["/match"]);
          }
        }
        break;
      case "/players-most-runs":
      case "/tournament-players":
      case "/players-profile":
        if (
          changeRoute !== "/players-most-runs" &&
          this.dataServiceClass.playerRunsDetailGet &&
          this.dataServiceClass.playerRunsDetailGet.statsKey !== undefined
        ) {
          this.dataServiceClass.headerTextSet =
            this.dataServiceClass.playerRunsDetailGet.statsName;
          this.router.navigate(["/players-most-runs"]);
        } else {
          this.dataServiceClass.headerTextSet =
            this.dataServiceClass.tournamentDetailGet.tourName;
          this.router.navigate(["/tournament-detail"], {
            queryParams: {
              tourId: this.dataServiceClass.tournamentDetailGet.id,
              tab: this.dataServiceClass.activeTabGet.toLocaleLowerCase(),
            },
          });
        }
        break;
      // case "/players-profile":
      // this.router.navigate(["/players-most-runs"]);
      // break;
    }
  }

  logOut() {
    //this.authService.logout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  menuClick(e) {}

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome("dashboard");
    return false;
  }
}
