import { Injectable } from "@angular/core";
@Injectable()
export class DataServiceClass {
  private _tournamentDetail: {};
  private _tournamentTeam: {};
  private _matchType: {};
  private _menuActive: string = "Home";
  private _activeTab: string = "Home";
  private _routes: string = "";
  private _headerText: string;
  private _matchDetails: any = {};
  private _playerRunsDetail: any = {};

  constructor() {
    //Init Tournament data
    this.tournamentDetailObj();
    this.tournamentTeamObj();
    this.initMenuActive();
    this.matchDetailsObj();
    this.headerTextActive();
    this.activeTabTour();
    this.playerRunsDetailObj();
  }

  public get playerRunsDetailGet(): any {
    return this._playerRunsDetail;
  }
  public set playerRunsDetailSet(value: any) {
    this._playerRunsDetail = value;
    this.setLocal("Admin", "playerRunsDetails", value);
  }

  public get headerTextGet(): string {
    return this._headerText;
  }
  public set headerTextSet(value: string) {
    this._headerText = value;
    this.setLocal("Admin", "headerText", value);
  }

  public get menuActiveGet(): string {
    return this._menuActive;
  }
  public set menuActiveSet(value: string) {
    this._menuActive = value;
    this.setLocal("Admin", "menuActive", value);
  }

  public get activeTabGet(): string {
    return this._activeTab;
  }
  public set activeTabSet(value: string) {
    this._activeTab = value;
    this.setLocal("Admin", "activeTab", value);
  }

  public get routesGet(): string {
    return this._routes;
  }
  public set routesSet(value: string) {
    this._routes = value;
  }

  public get matchTypeGet(): any {
    return this._matchType;
  }
  public set matchTypeSet(value: any) {
    this._matchType = value;
    //this.setLocal('Admin', 'matchTypeObj', value);
  }
  public get tournamentTeamGet(): any {
    return this._tournamentTeam;
  }

  public set tournamentTeamSet(value: any) {
    this._tournamentTeam = value;
    this.setLocal("Admin", "teamObj", value);
  }

  public get matchDetailsGet(): any {
    return this._matchDetails;
  }

  public set matchDetailsSet(value: any) {
    this._matchDetails = value;
    this.setLocal("Admin", "matchDetails", value);
  }

  private matchDetailsObj(): any {
    let matchObj = this.getLocal("Admin", "matchDetails");
    if (matchObj != undefined) {
      this._matchDetails = matchObj;
    } else {
      this._matchDetails = null;
    }
  }

  private tournamentDetailObj(): any {
    let tourObj = this.getLocal("Admin", "tourObj");
    if (tourObj != undefined) {
      this._tournamentDetail = tourObj;
    } else {
      this._tournamentDetail = null;
    }
  }

  private headerTextActive(): any {
    let headerText = this.getLocal("Admin", "headerText");
    if (headerText != undefined) {
      this._headerText = headerText;
    } else {
      this._headerText = null;
    }
  }

  private activeTabTour(): any {
    let activeTab = this.getLocal("Admin", "activeTab");
    if (activeTab != undefined) {
      this._activeTab = activeTab;
    } else {
      this._activeTab = null;
    }
  }

  private initMenuActive(): any {
    let menu = this.getLocal("Admin", "menuActive");
    if (menu != undefined) {
      this._menuActive = menu;
    } else {
      this._menuActive = null;
    }
  }

  private playerRunsDetailObj() {
    let playerRunObj = this.getLocal("Admin", "playerRunsDetails");
    if (playerRunObj != undefined) {
      this._playerRunsDetail = playerRunObj;
    } else {
      this._playerRunsDetail = null;
    }
  }

  private tournamentTeamObj() {
    let teamObj = this.getLocal("Admin", "storiesObj");
    if (teamObj != undefined) {
      this._tournamentTeam = teamObj;
    } else {
      this._tournamentTeam = null;
    }
  }

  //Tournament Data
  public get tournamentDetailGet(): any {
    return this._tournamentDetail;
  }

  public set tournamentDetailSet(value: any) {
    this._tournamentDetail = value;
    this.setLocal("Admin", "tourObj", value);
  }

  setLocal(module: string, key: string, data: any) {
    // this.removeLocal(module , key);
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
    }
    modObj[key] = data;
    localStorage.setItem(module, JSON.stringify(modObj));
  }

  getLocal(module: string, key: string) {
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
      localStorage.setItem(module, JSON.stringify(modObj));
    }
    if (key != undefined) return modObj[key];
    return modObj;
  }

  removeLocal(module: string, key: string) {
    if (key != undefined) {
      let modObj = JSON.parse(localStorage.getItem(module));
      if (modObj != undefined) {
        delete modObj[key];
        localStorage.setItem(module, JSON.stringify(modObj));
        return;
      }
    }
    localStorage.removeItem(module);
  }

  /**
   * Send the whatsapp message
   * @param  {string} message
   * @param  {any} mobile
   */
  public shareOnWhatsApp(message: string, mobile: any) {
    if (message == undefined || message == "") return;
    if (mobile == undefined) {
      window.location.href =
        "whatsapp://send?text=" + encodeURIComponent(message);
    } else {
      window.location.href =
        "whatsapp://send?phone=91" +
        mobile +
        "&text=" +
        encodeURIComponent(message);
    }
  }
}
