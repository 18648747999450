import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Url } from 'src/app/_helpers/Url';
import { DataServiceClass } from 'src/app/service/DataService.service';

@Component({
  selector: 'app-match-card',
  templateUrl: './match-card.component.html',
  styleUrls: ['./match-card.component.scss']
})
export class MatchCardComponent implements OnInit {
  public imagePath = Url.imageUrl;
  @Input() match: any;
  @Input() scoreBoardType: string;

  constructor(
    private dataServiceClass: DataServiceClass,
    private router:Router
  ) { }

  ngOnInit(): void {
  }

  onChangeScoreBoard() {
    switch (this.scoreBoardType) {
      case "completedScoreBoard":
        this.dataServiceClass.matchDetailsSet = this.match;
        this.dataServiceClass.headerTextSet =
          this.dataServiceClass.matchDetailsGet.matchName;
        this.router.navigate(["completedScoreBoard"]);
        break;
      case "liveScoreBoard":
        this.dataServiceClass.matchDetailsSet = this.match;
        this.router.navigate(["liveScoreBoard"]);
        break;
      case "upcomingplayersdetail":
        this.dataServiceClass.matchDetailsSet = this.match;
        this.router.navigate(["upcomingplayersdetail"]);
        break;
    }
  }

}
