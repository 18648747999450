import { environment } from "src/environments/environment";
export class Url {
  static imageUrl: string = environment.Image_Base_Url;
  //Tournament Api
  static Tournament_List_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/list"}`
  }`;
  static Tournament_Details_By_Id_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/{id}"}`
  }`;
  static List_Of_Torunament_Teams_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/{tournament}/teams"}`
  }`;
  static List_Of_Torunament_Players_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/{tournament}/players"}`
  }`;
  static List_Of_Story_Get: string = `${
    environment.Api_Base_Url + `${"v2/portal/story/list"}`
  }`;
  static List_Of_Tournament_stats_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/{tournament}/stats"}`
  }`;
  static List_Of_Tournament_AllStats_Get: string = `${
    environment.Api_Base_Url +
    `${"v2/portal/organisation/{organisation}/stats/summary"}`
  }`;
  static List_Of_Teams_Players_Get: string = `${
    environment.Api_Base_Url +
    `${"v2/portal/tournament/{tournament}/team/{team}/player/list"}`
  }`;
  static List_Of_Tournament_Matches_Get: string = `${
    environment.Api_Base_Url +
    `${"v2/portal/tournament/{tournament}/match/{type}/list"}`
  }`;
  static List_Of_Tournament_Points_Get: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/tour/{tournament}/teams/point"}`
  }`;
  static List_Of_Tournament_FairPlay_Get: string = `${
    environment.Api_Base_Url +
    `${"v2/portal/tournament/{tournament}/fairplay/list"}`
  }`;
  static List_Of_Tournament_PLayerMostRun_Get: string = `${
    environment.JAVA_Base_Url +
    `${"v1/portal/tour/{tournament}/stats/{statskey}/list"}`
  }`;
  // Match api
  static List_Of_Match_Get: string = `${
    environment.Api_Base_Url + `${"v2/portal/match/{type}/list "}`
  }`;
  static Recent_Matches_Team_Wise_Get: string = `${
    environment.Api_Base_Url + `${"v2/portal/match/team/{team}/list"}`
  }`;
  static Completed_ScoreBoard_Summary_Detail_Get: string = `${
    environment.Api_Base_Url + `${"v2/portal/match/{match_id}/score "}`
  }`;
  static Live_Match_ScoreBoard_Summary_Detail_Get: string = `${
    environment.Api_Base_Url + `${"v2/portal/match/{match_id}/live/score "}`
  }`;

  //scoreboard api
  static ScoreboardTeamPLayers: string = `${
    environment.Api_Base_Url + `${"v2/portal/match/{match}/player/list"}`
  }`;

  // Players

  // Matches Java APIs
  static TOURNAMENT_MATCHES_GET: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/{type}/matches"}`
  }`;
  static TOURNAMENT_MATCHES_SCORES_GET: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/match/{matchId}/score"}`
  }`;

  static TEAM_PLAYERS_GET: string = `${
    environment.JAVA_Base_Url + `${"v1/portal/match/{match}/player/list"}`
  }`;

  static PLAYERS_DETAILS_BY_ID_GET: string = `${
    environment.JAVA_Base_Url +
    `${"v1/portal/tour/{tourId}/players/{id}/stats"}`
  }`;
}
