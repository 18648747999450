<div class="mycard" (click)="onChangeScoreBoard()">
    <p class="font-14-12 text-center" style="margin-top: 4px;font-size: 14px;">{{match.tourName}}</p>
    <p class="font-14-12 text-center mb-2" style="font-size: 14px;">{{match.matchType}},{{match.matchDate}} > {{match.groundName}}</p>
    <div class="row m-0">
        <div class="col-5 pl-0 pr-0">
            <div class="row m-0" style="line-height: 22px;margin-top:10px">
                <div class="col-5 p-0">
                    <img class="liveMatchLogo" [src]="match.teamLogo1 == null ? 'assets/images/noImage.png' :imagePath + match.teamLogo1">
                </div>
                <div class="col-7 p-0" style="margin-top:5px !important;">
                    <p class="font-26-24 f-500 custom-runs">{{match.teamScore1}} <br>
                        <span class="font-14-12 f-400">{{match.teamOver1}}</span><br></p>
                </div>
            </div>
            <p class="font-14-12 f-500" style="font-size: 15px;font-weight: 600;">{{match.teamName1}}</p>
        </div>
        <div class="col-2 pl-0 pr-0">
            <div>
                <img src="assets/images/swamiji.jpg" style="width: 100%;">
            </div>
        </div>
        <div class="col-5 pl-0 pr-0 text-right">
            <div class="row m-0" style="line-height: 22px;margin-top:10px">

                <div class="col-7 p-0" style="margin-top:5px !important;">
                    <p class="font-26-24 f-500 custom-runs">{{match.teamScore2}} <br>
                        <span class="font-14-12 f-400">{{match.teamOver2}}</span><br></p>
                </div>
                <div class="col-5 p-0">
                    <img class="liveMatchLogo " [src]="match.teamLogo2 == null ? 'assets/images/noImage.png' : imagePath + match.teamLogo2">
                </div>
            </div>
            <p class="font-14-12 f-500" style="font-size: 15px;font-weight: 600;">{{match.teamName2}}</p>
        </div>

    </div>
    <p class="font-14-12 text-center f-500 mt-2" style="font-size: 14px;font-weight: 600;">{{match.matchSummary}}</p>
</div>